import {
  BadgeColourEnum,
  CertificateColourEnum,
  FeatureFlagEnum,
  Framework,
  QuestGoalStatusEnum,
  ReadingActivityEnum,
  ReadingGoalsQuizAreaEnum,
  ReadingGoalsQuizLevelEnum,
} from 're-client/graphql/graphql'
import type {
  AssignmentTaskFragment,
  CertificateFragmentFragment,
  CertificateSaveInput,
  PendingCertificateFragmentFragment,
  PlazaPollFragmentFragment,
  QuestGoalEssentialDataFragmentFragment,
  ReadingActivityFragmentFragment,
  ReadingGoalsQuizFragmentFragment,
  ReadingGoalsQuizSummaryFragmentFragment,
  StudentMyAwardsFragmentFragment,
  StudentProgressFragmentFragment,
  StudentQuestDataFragmentFragment,
  StudentUserFragmentFragment,
} from 're-client/graphql/graphql'
import readingActivities from './fixtures/reading-activities'
import readingGaolsQuizzes from './fixtures/reading-goals-quiz'
import puzzleScores from './fixtures/puzzle-scores'
import studentItems from './fixtures/student-items'

type AssignmentTask = Extract<
  AssignmentTaskFragment['assignmentTask'],
  { __typename: string }
>
type AvatarDna = Student['dna']
type Badge = StudentMyAwardsFragmentFragment['badges'][number]
type Certificate = CertificateFragmentFragment
type FeatureFlag = Student['featureFlags'][number]
type PendingCertificate = PendingCertificateFragmentFragment
type PlazaPoll = PlazaPollFragmentFragment
type Progress = StudentProgressFragmentFragment['progress']
export type ClinkerCastleProgress = Progress['clinkerCastle']
export type DrivingTestProgress = Progress['drivingTests']
export type LessonsProgress = Progress['lessons']
export type SpellingProgress = Progress['spelling']
type Quest = Extract<
  StudentQuestDataFragmentFragment['quest'],
  { __typename: string }
>
type QuestGoalEssential = Extract<
  QuestGoalEssentialDataFragmentFragment['essentialQuestGoal'],
  { __typename: string }
>
type QuestGoalEasy = Extract<Quest['easyGoal'], { __typename: string }>
type QuestGoalPrimary = Extract<Quest['primaryGoal'], { __typename: string }>
type ReadingActivity = ReadingActivityFragmentFragment
type ReadingGoalsQuiz = ReadingGoalsQuizFragmentFragment
type ReadingGoalsQuizSummary = ReadingGoalsQuizSummaryFragmentFragment
export type Student = StudentUserFragmentFragment

interface Lesson {
  id: string
  precinct: string
  locale: string
  book: {
    code: string
  }
  position: number
  activities: unknown[]
}

export interface PuzzleScore {
  id: string
  puzzle_name: string
  score: number
}

interface StudentItem {
  id: number
  code: string
  department: string
  state: unknown
  image_url: unknown
  animation_url: unknown
  animation_json: unknown
}

const ONE_DAY = 24 * 60 * 60 * 1000

/** Interal database state */
let state = initialState()

/** External database methods */
export const db = {
  acknowledgeQuestGoal,
  createAssignmentTask,
  createBadge,
  createCertificate,
  createEssentialQuestGoal,
  createFeatureFlag,
  createPendingCertificate,
  createPuzzleScore,
  createQuestGoal,
  createReadingActivity,
  createReadingGoalsQuiz,
  createReadingGoalsQuizSummary,
  createReadingLesson,
  createSpellingLesson,
  createStorylandLesson,
  createStudent,
  deleteAssignmentTask,
  deleteBadges,
  deletePendingCertificate,
  findBadge,
  findPuzzleScore,
  getMapLesson,
  getLessonActivities,
  getPlazaPolls,
  getPendingCertificateUploadUrl,
  getPuzzleScores,
  getReadingActivities,
  getReadingGoalQuizzes,
  getStudent,
  getStudentItems,
  loadFixtures,
  reset,
  saveCertificate,
  updateProgress,
  updateQuestGoal,
  updateStudent,
}

/** Reset the database to the initial state */
function reset() {
  state = initialState()
  return db
}

function createStudent(input?: Partial<Student>) {
  state.student = {
    __typename: 'Student',
    avatarUrl: 'https://avatars.static.readingeggs.com/re/8-1-36-1-1-1-1-2.svg',
    backgroundMusicDisabled: false,
    eggs: 30765,
    firstName: 'child_au',
    gamesAccess: true,
    id: '1',
    lastName: 'Tzzz',
    locale: 'au',
    name: 'Child Au T.',
    playroomAccess: true,
    remoteId: 'me',
    ...input,
    get dna() {
      return state.dna
    },
    get dnaHash() {
      return JSON.stringify(this.dna)
    },
    get featureFlags() {
      return values(state.featureFlags)
    },
  }
}

function createBadge(input: Partial<Badge>) {
  const badge: Badge = {
    __typename: 'Badge',
    id: '1',
    colour: BadgeColourEnum.Bronze,
    lesson: 1,
    ...input,
  }

  state.badges.set(badge.id, badge)
}

function deleteBadges() {
  state.badges = new Map()
}

function createCertificate(input?: Partial<Certificate>) {
  const certificate: Certificate = {
    __typename: 'Certificate',
    id: '1893697',
    map: 7,
    scorePercentage: 100,
    url: 'https://certificates.blake-staging.com/re/legacy/WzcsImdvbGQiLDEwMCwiQ2hpbGQgQXUgVC4iLDE2ODM2MDc1MjUzOTNd.svg',
    createdAt: new Date(Date.UTC(2017, 2, 5)).toISOString(),
    ...input,
  }

  state.certificates.set(certificate.id, certificate)

  return certificate
}

function createReadingLesson(
  activityCount = 1,
  input?: Partial<ReadingActivity>,
) {
  for (let i = 1; i <= activityCount; i++) {
    createReadingActivity({
      ...input,
      activityInLesson: i,
    })
  }
}

function createSpellingLesson(activityCount = 1, input?: Partial<Lesson>) {
  const spellingLesson: Lesson = {
    id: '1',
    precinct: 'spelling',
    locale: 'au',
    book: {
      code: 're_student_one',
    },
    get position(): number {
      return Number(this.id)
    },
    get activities() {
      return Array.from({ length: activityCount }, (_, i) => ({
        id: `${this.id}- ${(i + 1).toFixed()}`,
      }))
    },
    ...input,
  }

  state.spellingLessons.set(spellingLesson.id, spellingLesson)

  return spellingLesson
}

function createStorylandLesson(activityCount = 1, input?: Partial<Lesson>) {
  const storylandLesson: Lesson = {
    id: '1',
    precinct: 'clinker_castle',
    locale: 'au',
    book: {
      code: 're_student_one',
    },
    get position(): number {
      return Number(this.id)
    },
    get activities() {
      return Array.from({ length: activityCount }, (_, i) => ({
        id: `${this.id}- ${(i + 1).toFixed()}`,
      }))
    },
    ...input,
  }

  state.storylandLessons.set(storylandLesson.id, storylandLesson)

  return storylandLesson
}

function createEssentialQuestGoal(input: Partial<QuestGoalEssential>) {
  switch (input.__typename) {
    case 'QuestGoalLesson':
      state.essentialQuestGoal = {
        __typename: 'QuestGoalLesson',
        eggReward: 20,
        id: '1',
        lesson: 1,
        progressCurrent: 0,
        progressTotal: 9,
        ...input,
      }
      break

    case 'QuestGoalMap':
      state.essentialQuestGoal = {
        __typename: 'QuestGoalMap',
        eggReward: 20,
        id: '1',
        map: 1,
        ...input,
      }
      break

    default:
      throw new Error(`Unknown essential quest goal type: ${input.__typename}`)
  }
}

function updateStudent(input: Partial<Student> | null | undefined) {
  if (input === null) {
    state.student = input
  } else {
    createStudent({
      ...state.student,
      ...input,
    })
  }

  return state.student
}

function getStudent() {
  if (state.student) {
    return {
      ...state.student,
      readingGoalsMinimumLevel: ReadingGoalsQuizLevelEnum.K,
      readingGoalsQuizSecondsToNextWeek: 1000,
      get assignmentTask() {
        return first(state.assignmentTasks)
      },

      get badges() {
        return values(state.badges)
      },
      set badges(badges) {
        state.badges = new Map()

        for (const badge of badges) {
          state.badges.set(badge.id, badge)
        }
      },
      get certificates() {
        return values(state.certificates)
      },
      get essentialQuestGoal() {
        return state.essentialQuestGoal
      },
      get progress() {
        return state.progress
      },
      set progress(progress) {
        state.progress = progress
      },
      get pendingCertificate() {
        return state.pendingCertificate
      },
      get quest() {
        return state.quest
      },
      get readingGoalsQuizSummaries() {
        return values(state.readingGoalsQuizSummaries)
      },
    }
  }

  return null
}

function createStudentItem(input: Partial<StudentItem>) {
  const studentItem: StudentItem = {
    id: 0,
    code: 'item_code',
    department: 'item_department',
    state: null,
    image_url: null,
    animation_url: null,
    animation_json: null,
    ...input,
  }

  state.studentItems.set(studentItem.code, studentItem)
}

function getStudentItems() {
  return {
    items: values(state.studentItems),
  }
}

function getPendingCertificateUploadUrl() {
  const { pendingCertificate } = state

  if (pendingCertificate) {
    return {
      __typename: pendingCertificate.__typename,
      id: pendingCertificate.id,
      get uploadUrl(): string {
        return `/re/${this.id}.svg`
      },
    }
  }

  return null
}

function getPlazaPolls() {
  return values(state.plazaPolls)
}

function getReadingGoalQuizzes() {
  return values(state.readingGoalsQuizzes)
}

function getLessonActivities(precinct: string, id: string) {
  switch (precinct) {
    case 'clinker_castle': {
      const lesson = findBy(
        state.storylandLessons,
        (lesson) => lesson.id === id,
      )
      return lesson
    }

    case 'reading': {
      const activities = filterBy(
        state.readingActivities,
        (activity) => activity.lessonInPrecinct === parseInt(id, 10),
      )
      return { activities: values(activities) }
    }

    case 'spelling': {
      const lesson = findBy(state.spellingLessons, (lesson) => lesson.id === id)
      return lesson
    }

    default:
      throw new Error(`Unknown precinct: ${precinct}`)
  }
}

function getMapLesson(precinct: string, id: string) {
  switch (precinct) {
    case 'clinker_castle':
      return findBy(state.storylandLessons, (lesson) => lesson.id === id)

    case 'spelling':
      return findBy(state.spellingLessons, (lesson) => lesson.id === id)

    default:
      throw new Error(`Unknown precinct: ${precinct}`)
  }
}

function createReadingActivity(input: Partial<ReadingActivity>) {
  const readingActivity: ReadingActivity = {
    __typename: 'ReadingActivity',
    activityInLesson: 1,
    activityType: ReadingActivityEnum.Interactive,
    framework: Framework.Caper,
    get id() {
      return `${this.precinct}/map-${this.map.toFixed()}/lesson-${this.lessonInMap.toFixed()}/activity-${this.activityInLesson.toFixed()}`
    },
    lessonInMap: 1,
    lessonInPrecinct: 1,
    get manifestBundle() {
      return `activities/${this.activityInLesson.toFixed()}`
    },
    get manifestKey() {
      return ['activities', this.activityInLesson.toFixed()]
    },
    map: 1,
    precinct: 'reading',
    ...input,
  }

  state.readingActivities.set(readingActivity.id, readingActivity)

  return readingActivity
}

function deleteAssignmentTask(id: string) {
  state.assignmentTasks = filterBy(
    state.assignmentTasks,
    (task) => !('id' in task && task.id === id),
  )
}

function getReadingActivities() {
  return values(state.readingActivities)
}

function createReadingGoalsQuiz(input: Partial<ReadingGoalsQuiz>) {
  const quiz: ReadingGoalsQuiz = {
    __typename: 'ReadingGoalsQuiz',
    area: ReadingGoalsQuizAreaEnum.PrintConcepts,
    badgeReward: 'green_bunny',
    id: 'k-1-print_concepts-features_of_books',
    level: ReadingGoalsQuizLevelEnum.K,
    name: 'Features of books',
    ...input,
  }

  state.readingGoalsQuizzes.set(quiz.id, quiz)
}

function createAssignmentTask(input?: Partial<AssignmentTask>): AssignmentTask {
  let task: AssignmentTask

  switch (input?.__typename) {
    case 'AssignmentTaskClinkerCastle':
      task = {
        id: '1',
        targetRouteParams: [],
        assignmentUuid: 'clinker-castle-assignment-uuid',
        endAt: new Date(Date.now() + ONE_DAY).toISOString(),
        thumbnailUrl: `https://placebear.com/200/150`,
        title: 'Clinker Castle Assignment Title',
        lessonId: '1',
        activityId: '1',
        __typename: 'AssignmentTaskClinkerCastle',
        ...input,
      }
      break

    case 'AssignmentTaskDrivingTests':
      task = {
        id: '1',
        targetRouteParams: [],
        assignmentUuid: 'driving-test-assignment-uuid',
        endAt: new Date(Date.now() + ONE_DAY).toISOString(),
        thumbnailUrl: `https://placebear.com/200/150`,
        title: 'Driving Test Assignment Title',
        lessonId: '1',
        gradePosition: 1,
        category: 'sight_words',
        __typename: 'AssignmentTaskDrivingTests',
        ...input,
      }
      break

    case 'AssignmentTaskLesson':
      task = {
        id: '1',
        targetRouteParams: [],
        assignmentUuid: 'lesson-assignment-uuid',
        endAt: new Date(Date.now() + ONE_DAY).toISOString(),
        thumbnailUrl: `https://placebear.com/200/150`,
        title: 'Lesson Assignment Title',
        lessonId: '1',
        activityId: '1',
        __typename: 'AssignmentTaskLesson',
        ...input,
      }
      break

    case 'AssignmentTaskSpelling':
      task = {
        id: '1',
        targetRouteParams: [],
        assignmentUuid: 'spelling-assignment-uuid',
        endAt: new Date(Date.now() + ONE_DAY).toISOString(),
        thumbnailUrl: `https://placebear.com/200/150`,
        title: 'Spelling Assignment Title',
        lessonId: '1',
        activityId: '1',
        __typename: 'AssignmentTaskSpelling',
        ...input,
      }
      break

    case 'Error':
      task = {
        ...input,
        __typename: 'Error',
        message: 'assignment-task-error',
      }
      break

    default:
      throw new Error(`Unknown assignment task type: ${input?.__typename}`)
  }

  if (task.__typename !== 'Error') {
    state.assignmentTasks.set(task.id, task)
  }

  return task
}

function saveCertificate(input: CertificateSaveInput) {
  let certificate: Certificate | null = null

  const map = state.pendingCertificate?.map

  if (map) {
    state.certificates = filterBy(
      state.certificates,
      (cert) => cert.map !== map,
    )
  }

  if (input.pendingCertificateId === state.pendingCertificate?.id) {
    certificate = createCertificate({
      id: input.pendingCertificateId,
      map: state.pendingCertificate.map,
      scorePercentage: state.pendingCertificate.scorePercentage,
      url: `/re/${state.pendingCertificate.id}.svg`,

      ...input,
    })

    state.pendingCertificate = null
  }

  return certificate
}

function updateProgress(
  input:
    | ((input: Progress) => {
        clinkerCastle: ClinkerCastleProgress
        drivingTests: DrivingTestProgress
        lessons: LessonsProgress
        spelling: SpellingProgress
      })
    | Partial<{
        clinkerCastle: Partial<ClinkerCastleProgress> | undefined
        drivingTests: Partial<DrivingTestProgress> | undefined
        lessons: Partial<LessonsProgress> | undefined
        spelling: Partial<SpellingProgress> | undefined
      }>,
) {
  let update = input

  if (typeof input !== 'function') {
    update = (progress) => ({
      clinkerCastle: {
        ...progress.clinkerCastle,
        ...input.clinkerCastle,
      },
      drivingTests: {
        ...progress.drivingTests,
        ...input.drivingTests,
      },
      lessons: {
        ...progress.lessons,
        ...input.lessons,
      },
      spelling: {
        ...progress.spelling,
        ...input.spelling,
      },
    })
  }

  if (state.student && typeof update === 'function') {
    state.progress = {
      __typename: 'Progress',
      ...update(state.progress),
    }
  }
}

function updateQuestGoal<Goal extends QuestGoalPrimary | QuestGoalEasy>(
  questGoal: Goal,
  input: Partial<Goal>,
) {
  Object.assign(questGoal, input)
}

function createFeatureFlag(input: Partial<FeatureFlag>) {
  const featureFlag: FeatureFlag = {
    __typename: 'FeatureFlag',
    name: 'feature_flag',
    type: FeatureFlagEnum.Boolean,
    value: 'true',
    ...input,
  }

  state.featureFlags.set(featureFlag.name, featureFlag)
}

function createPendingCertificate(input?: Partial<PendingCertificate>) {
  state.pendingCertificate = {
    __typename: 'PendingCertificate',
    colour: CertificateColourEnum.Gold,
    scorePercentage: 100,
    map: 1,
    quizResultCreatedAt: '2023-02-01T00:00:00.000Z',
    id: 'uuid',
    ...input,
  }
}

function deletePendingCertificate() {
  state.pendingCertificate = null
}

function updateQuest(input?: Partial<Quest>) {
  let easyGoal = state.quest?.easyGoal ?? null
  let primaryGoal = state.quest?.primaryGoal ?? null

  if (input?.easyGoal) {
    easyGoal = {
      ...easyGoal,
      ...input.easyGoal,
    }
  }

  if (input?.primaryGoal) {
    primaryGoal = {
      ...primaryGoal,
      ...input.primaryGoal,
    }
  }

  if (state.student) {
    state.quest = {
      __typename: 'Quest',
      easyGoal,
      primaryGoal,
    }
  }
}

function createQuestGoal(input?: Partial<QuestGoalPrimary | QuestGoalEasy>) {
  switch (input?.__typename) {
    case 'QuestGoalEarnEggs':
      updateQuest({
        easyGoal: {
          __typename: 'QuestGoalEarnEggs',
          eggReward: 20,
          id: '1',
          progressCurrent: 0,
          progressTotal: 9,
          status: QuestGoalStatusEnum.Incomplete,
          ...input,
        },
      })
      break

    case 'QuestGoalLesson':
      updateQuest({
        primaryGoal: {
          __typename: 'QuestGoalLesson',
          eggReward: 20,
          id: '1',
          lesson: 1,
          progressCurrent: 0,
          progressTotal: 9,
          status: QuestGoalStatusEnum.Incomplete,
          ...input,
        },
      })
      break

    case 'QuestGoalMap':
      updateQuest({
        primaryGoal: {
          __typename: 'QuestGoalMap',
          eggReward: 20,
          id: '1',
          map: 1,
          status: QuestGoalStatusEnum.Incomplete,
          complete: false,
          ...input,
        },
      })
      break

    default:
      throw new Error(`Unknown quest goal type: ${input?.__typename}`)
  }
}

function acknowledgeQuestGoal(goalId: string) {
  let goal

  if (state.quest?.primaryGoal?.id === goalId) {
    goal = state.quest.primaryGoal
  } else if (state.quest?.easyGoal?.id === goalId) {
    goal = state.quest.easyGoal
  }

  if (goal) {
    goal.status = QuestGoalStatusEnum.Complete

    if (state.student) {
      state.student.eggs += goal.eggReward
    }
  }

  return goal
}

function createPuzzleScore(input?: Partial<PuzzleScore>) {
  const puzzleScore: PuzzleScore = {
    id: 'puzzle_name',
    puzzle_name: 'puzzle-name',
    score: 100,
    ...input,
  }

  state.puzzleScores.set(puzzleScore.id, puzzleScore)

  return puzzleScore
}

function findPuzzleScore(id: string) {
  return findBy(state.puzzleScores, (score) => score.id === id)
}

function getPuzzleScores() {
  return values(state.puzzleScores)
}

function findBadge(
  predicate: (value: Badge, key: string, map: Map<string, Badge>) => boolean,
) {
  return findBy(state.badges, predicate)
}

function createReadingGoalsQuizSummary(
  input?: Partial<ReadingGoalsQuizSummary>,
) {
  const quizSummary: ReadingGoalsQuizSummary = {
    __typename: 'ReadingGoalsQuizSummary',
    attemptsThisWeek: 1,
    eggsReward: 0,
    id: '1',
    passed: false,
    secondsToPass: 0,
    timestamp: new Date().toISOString(),
    ...input,
  }

  state.readingGoalsQuizSummaries.set(quizSummary.id, quizSummary)
}

function loadFixtures(
  ...names: (
    | 'puzzleScores'
    | 'readingActivities'
    | 'readingGoalsQuizzes'
    | 'studentItems'
  )[]
) {
  if (names.includes('readingActivities')) {
    for (const activity of readingActivities()) {
      createReadingActivity(activity)
    }
  }

  if (names.includes('readingGoalsQuizzes')) {
    for (const quiz of readingGaolsQuizzes()) {
      createReadingGoalsQuiz(quiz)
    }
  }

  if (names.includes('puzzleScores')) {
    for (const score of puzzleScores) {
      createPuzzleScore(score)
    }
  }

  if (names.includes('studentItems')) {
    for (const item of studentItems.items) {
      createStudentItem(item)
    }
  }
}

function initialState() {
  const dna: AvatarDna = {
    __typename: 'AvatarDna',
    head: 'default_head_4',
    arms: 'default_arms_2',
    legs: 'default_legs_1',
    egg: 'egg_88',
    decal: 'decal_27',
    sunnies: 'default_sunnies_2',
    extra: 'extra_10',
    hat: 'hat_13',
  }

  const progress: Progress = {
    __typename: 'Progress',
    lessons: {
      __typename: 'LessonsProgress',
      currentMap: 1,
      currentLesson: '1',
      currentActivity: 1,
      showPlacementTest: false,
    },
    spelling: {
      __typename: 'SpellingProgress',
      currentMap: 1,
      currentLesson: '1',
      currentActivity: 1,
      showPlacementTest: false,
    },
    clinkerCastle: {
      __typename: 'ClinkerCastleProgress',
      currentMap: 1,
      currentLesson: 1,
      currentActivity: 1,
    },
    drivingTests: {
      __typename: 'DrivingTestProgress',
      sightWords: 1,
      contentWords: 1,
      lettersAndSounds: 1,
    },
  }

  const featureFlags = new Map<string, FeatureFlag>()

  const student = null as Student | null
  const quest = null as Quest | null
  const essentialQuestGoal = null as QuestGoalEssential | null
  const pendingCertificate = null as PendingCertificate | null

  const certificates = new Map<string, Certificate>()
  const assignmentTasks = new Map<string, AssignmentTask>()
  const badges = new Map<string, Badge>()
  const readingActivities = new Map<string, ReadingActivity>()
  const spellingLessons = new Map<string, Lesson>()
  const storylandLessons = new Map<string, Lesson>()
  const readingGoalsQuizzes = new Map<string, ReadingGoalsQuiz>()
  const puzzleScores = new Map<string, PuzzleScore>()
  const readingGoalsQuizSummaries = new Map<string, ReadingGoalsQuizSummary>()
  const plazaPolls = new Map<string, PlazaPoll>()
  const studentItems = new Map<string, StudentItem>()

  return {
    assignmentTasks,
    badges,
    certificates,
    dna,
    essentialQuestGoal,
    featureFlags,
    pendingCertificate,
    plazaPolls,
    progress,
    puzzleScores,
    quest,
    readingActivities,
    readingGoalsQuizSummaries,
    readingGoalsQuizzes,
    spellingLessons,
    storylandLessons,
    student,
    studentItems,
  }
}

function filterBy<K, V>(
  map: Map<K, V>,
  predicate: (value: V, key: K, map: Map<K, V>) => boolean,
) {
  const newMap = new Map<K, V>()

  for (const [id, value] of map) {
    if (predicate(value, id, map)) {
      newMap.set(id, value)
    }
  }

  return newMap
}

function findBy<K, V>(
  map: Map<K, V>,
  predicate: (value: V, key: K, map: Map<K, V>) => boolean,
) {
  for (const [id, value] of map) {
    if (predicate(value, id, map)) {
      return value
    }
  }

  return null
}

function values<V>(map: Map<unknown, V>): V[] {
  return Array.from(map.values())
}

function first<V>(map: Map<unknown, V>): V | null {
  const result = map.values().next()
  return result.done ? null : result.value
}
