import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type LessonsService from 're-client/services/lessons'
import type UrlMakerService from 're-client/services/url-maker'
import type ActivityService from 're-client/services/activity'
import type UserService from 're-client/services/user'
import type CaperLoaderService from 're-client/services/caper-loader'
import type { InteractiveConfig } from '@blakeelearning/content-loader-core'
import type ReleaseChecker from '@blakeelearning/app-refresher/release-checker/service'
import type StudentProgressService from 're-client/services/student-progress'

interface ParamsForRoute {
  map_id: string
}

interface RouteModel {
  mapId: number
  isOnFinalMap: boolean
  nextMapId: number
  previousMapId: number
  interactiveConfig: InteractiveConfig
}

function isStudentOnNewMap({
  currentActivity,
  currentLesson,
}: {
  currentActivity: number
  currentLesson: string
}) {
  return !!(currentActivity === 1 && parseInt(currentLesson, 10) === 1)
}

export default class LessonsMapIndexRoute extends Route<
  RouteModel,
  ParamsForRoute
> {
  @service
  declare studentProgress: StudentProgressService

  @service
  declare releaseChecker: ReleaseChecker

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare user: UserService

  @service
  declare activity: ActivityService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare lessons: LessonsService

  override async model() {
    const { currentMap, isOnFinalMap, nextMapId, previousMapId } = this.lessons

    const mapId = currentMap
    const avatarJsonRequest = this.caperLoader.avatarJsonRequest()
    const quizComplete = this.lessons.isQuizComplete
    const { currentLesson } = this.studentProgress.lessons
    const isMapUnblocked = isStudentOnNewMap(this.studentProgress.lessons)
    const first_map_in_session =
      sessionStorage.getItem('has_seen_reggie_bubble') === 'true' ? false : true
    if (first_map_in_session)
      sessionStorage.setItem('has_seen_reggie_bubble', 'true')

    const variables = {
      student: this.user.student,
      lesson_number: currentLesson,
      map_number: mapId,
      quiz_complete: quizComplete,
      first_map_in_session: first_map_in_session,
      map_unblocked: isMapUnblocked,
    }

    const mapUrl = this.urlMaker.urlForInteractive('reading/map', mapId)
    const manifests = [mapUrl, avatarJsonRequest]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      mapId,
      isOnFinalMap,
      nextMapId,
      previousMapId,
      interactiveConfig,
    }
  }
}
