import { action } from '@ember/object'
import Controller from '@ember/controller'
import { inject as service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'
import type LessonsService from 're-client/services/lessons'

export default class LessonsMapIndexController extends Controller {
  @service
  declare router: RouterService

  @service
  declare lessons: LessonsService

  @action
  playLesson(lessonId: number) {
    void this.router.transitionTo('lessons.lesson', lessonId)
  }

  @action
  playQuiz() {
    void this.router.transitionTo('lessons.map.quiz', this.lessons.currentMap)
  }

  @action
  prevMap() {
    void this.router.transitionTo(
      'lessons.map.index',
      this.lessons.previousMapId,
    )
  }

  @action
  nextMap() {
    void this.router.transitionTo('lessons.map.next')
  }
}

declare module '@ember/controller' {
  interface Registry {
    'lessons/map/index': LessonsMapIndexController
  }
}
